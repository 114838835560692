<!--
 * @Description: 转nc
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-26 16:09:06
 * @LastEditors: Seven
 * @LastEditTime: 2021-12-02 17:41:19
-->
<template>
  <div class="transferNc">
    <div ref="contentTop">
      <div style="width: 94%; margin: 0 auto">
        <Search
          v-if="searchParamList.length > 0"
          :searchAllParam="searchAllParam"
          @search="search"
          @reset="reset"
          @searchMore="searchMore"
          @searchList="searchList"
          :searchParamList="searchParamList"
          :searchParamFromApi="searchParamFromApi"
          code="appointment"
        />
      </div>
      <div class="line"></div>
    </div>
    <el-row style="width: 94%; margin: 0 auto">
      <el-col>
        <el-table
          highlight-current-row
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          v-loading="listLoading"
          element-loading-text="拼命加载中"
          :height="tableHeight"
          :row-style="{ height: '20px' }"
          :cell-style="{ padding: '0px' }"
          :header-cell-style="{ background: '#e5f2ff' }"
          @sort-change="sortChange"
        >
          <el-table-column
            type="selection"
            width="55"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="转商机申请单号"
            prop="transferCode"
            min-width="210"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="学员姓名"
            prop="name"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <el-button type="text" @click="openDetail(scope.row)">
                {{ scope.row.name }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="归属销售"
            prop="userName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="潜在学员编号"
            prop="ncStudentCode"
            min-width="150"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="转商机操作人"
            prop="createUserName"
            min-width="120"
            
          >
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="转商机结果"
            prop="statusName"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <div :style="{color: scope.row.status?'green':'red'}">
                {{ scope.row.statusName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="创建时间"
            prop="createTime"
            min-width="150"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="有效期结束日期"
            prop="validDate"
            min-width="150"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="有效状态"
            prop="validStatusName"
            min-width="120"
            
          >
            <template slot-scope="scope">
              <div :style="{color: scope.row.validStatus?'green':'red'}">
                {{ scope.row.validStatusName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约老师"
            prop="teacherName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="预约校区"
            prop="schoolName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="转nc类型"
            prop="transferTypeName"
            min-width="120"
            
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="最后修改时间"
            prop="updateTime"
            min-width="150"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column :show-overflow-tooltip="true"
            label="修改人"
            prop="updateUserName"
            min-width="150"
            
            sortable="custom"
          ></el-table-column>
          <el-table-column
            label="操作"
            min-width="140"
            
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button v-if="scope.row.validStatus === 1 && permUtil.WbCusotmer.transferUpdate()" type="text" @click="edit(scope.row)"
                >修改申请</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="page_test_bottom">
          <el-pagination
            background
            @size-change="sizeChange"
            @current-change="currentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageParam.pageSize"
            :total="total"
          />
        </div>
      </el-col>
    </el-row>
    <HqModal :visible="eidtShow" title="修改申请" width="400" @on-cancel="cancel">
      <v-form ref="form" lazy-validation>
        <el-row :gutter="10">
          <el-col :span="24">
            <v-select
              v-model="form.provinceCode"
              :items="province"
              :menu-props="{ bottom: true, offsetY: true }"
              label="省份"
              item-text="name"
              item-value="schoolId"
              outlined
              :dense="true"
              :height="32"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
              @change="provinceChange"
            ></v-select>
          </el-col>
          <el-col :span="24">
            <v-select
              v-model="form.schoolNcId"
              :items="schoolList"
              :menu-props="{ bottom: true, offsetY: true }"
              label="预约校区"
              item-text="name"
              item-value="schoolId"
              outlined
              :dense="true"
              :height="32"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
              @change="changeSchool"
            ></v-select>
          </el-col>
          <el-col :span="24">
            <v-select
              v-model="form.teacherCode"
              :items="teacherList"
              :menu-props="{ bottom: true, offsetY: true }"
              label="招生老师"
              item-text="name"
              item-value="code"
              outlined
              :dense="true"
              :height="32"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
              @change="changeTeacher"
            ></v-select>
          </el-col>
        </el-row>
      </v-form>
      <div slot="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="editSave" type="primary">确认</el-button>
      </div>
    </HqModal>
    <!-- 详情 -->
    <Detail ref="drawer" :method="getTransferNcList" />
  </div>
</template>
<script>
import {
  getUserCustomQuery,
  getCustomerPhone,
  getUserList,
  getProvinceList,
  getNcTeacher,
  getRecruitSpinner
} from "@/api/public";
import { getTransferNcList, getTransferNcUpdate } from "@/api/customer";
import Search from "@/components/search";
import Detail from "./components/detail";

export default {
  components: {
    Search,
    Detail,
  },
  data() {
    return {
      activeName: "all",
      tabList: [
        { name: "全部", path: "all" },
        { name: "未到访", path: "today" },
        { name: "预约到访", path: "revice" },
        { name: "预约到访流失", path: "privte" },
      ],
      tableData: [],
      listLoading: false,
      total: 0,
      pageParam: {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      },
      searchShow: false,
      searchAllParam: [
        { name: "name", label: "学员姓名", type: "input", value: "" },
        { name: "phone", label: "手机", type: "input", value: "" },
        {
          name: "createTime",
          label: "创建时间",
          type: "date",
          value: [],
          ref: "create",
          show: false,
        },
        {
          name: "teacherName",
          label: "预约老师",
          type: "input",
          value: "",
        },
        {
          name: "schoolName",
          label: "预约校区",
          type: "input",
          value: "",
        },
        {
          name: "appointmentTime",
          label: "预约时间",
          type: "date",
          value: [],
          ref: "appoint",
          show: false,
        },
        {
          name: "userId",
          label: "归属销售",
          type: "select",
          value: "",
          option: [],
          text: "name",
          id: "id",
        },
        {
          name: "createId",
          label: "创建人",
          type: "select",
          value: "",
          option: [],
          text: "name",
          id: "id",
        },
        {
          name: "status",
          label: "转NC结果",
          type: "select",
          value: "",
          option: [
            { name: "成功", id: 1 },
            { name: "失败", id: 0 },
          ],
          text: "name",
          id: "id",
        },
        {
          name: "validStatus",
          label: "有效状态",
          type: "select",
          option: [
            { name: "有效", id: 1 },
            { name: "已失效", id: 0 },
          ],
          value: "",
          text: "name",
          id: "id",
        },
        { name: "customerId", label: "客户编号", type: "input", value: "" },
      ],
      searchParamList: [],
      defaultParamList: ["name", "phone", "createTime"],
      searchParamFromApi: [],
      eidtShow: false,
      form: {
        provinceCode: "",
        schoolNcId: "",
        teacherCode: "",
      },
      checkList: [
        { label: "未到访", value: 0 },
        { label: "预约到访流失", value: 1 },
        { label: "预约到访", value: 2 },
      ],
      phoneList: [],
      province: [],
      schoolList: [],
      teacherList: [],
      currCol: null,
      // 页面是否有滚动条
      isScroll: true,
      topHeight: 0
    };
  },
  computed: {
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
  mounted() {
    this.getSearchList();
    this.getTransferNcList();
    getUserList().then((data) => {
      this.searchAllParam.map((item) => {
        if ( item.name === "userId" || item.name === "createId") {
          item.option = data.result;
        }
      });
    });

    getProvinceList({ type: "PROVINCE" }).then(
      (data) => (this.province = data.result)
    );
  },
  methods: {
    // tab切换
    handleClick(index) {
      this.activeName = index;
      this.$router.push({ query: { type: this.activeName } });
    },
    // 更新自定义查询
    searchList() {
      this.getSearchList();
    },
    // 更多查询条件
    searchMore(value) {
      this.searchParamList = value
        ? this.searchAllParam
        : this.defaultParamList;
      this.$nextTick(() => {
        this.topHeight = this.$refs.contentTop.offsetHeight + 40
      })
    },
    // 获取自定义查询的默认查询字段
    async getSearchList() {
      const { code, result } = await getUserCustomQuery("appointment");
      if (code === 0) {
        this.defaultParamList = ["name", "phone", "createTime"]
        if (result && result.fieldData && JSON.parse(result.fieldData).length > 0) {
          this.searchParamFromApi = JSON.parse(result.fieldData);
          
          this.defaultParamList = []
          this.searchParamFromApi.map(() => {
            this.defaultParamList.push("")
          })
          let index
          this.searchAllParam.filter((item) => {
            index = this.searchParamFromApi.findIndex(i => i === item.name)
            this.defaultParamList[index] = item
          });
        } else {
          this.defaultParamList = this.searchAllParam.filter((item) =>
            this.defaultParamList.includes(item.name)
          );
        }
        this.searchParamList = this.defaultParamList;
        this.$nextTick(() => {
          this.topHeight = this.$refs.contentTop.offsetHeight + 40
        })
      }
    },
    // 打开详情
    openDetail(row) {
      this.$refs.drawer.init(row)
    },
    // 获取列表数据
    async getTransferNcList() {
      this.listLoading = true;
      const { code, result, msg } = await getTransferNcList({...this.pageParam, ...this.searchParam});
      if (code === 0) {
        this.tableData = result.list;
        this.total = result.total;
        this.$nextTick(() => {
          this.isScroll = document.body.scrollHeight > window.innerHeight
        })
      } else {
        this.$message.error(msg)
      }
      this.listLoading = false;
    },
    // 查询
    search() {
      this.searchParam = {};
      this.searchParamList.map((item) => {
        if (item.value == 0 || item.value  || ( Array.isArray(item.value)  && item.value.length)) {
          if (item.type === "date") {
            if (item.name === "createTime") {
              this.searchParam.beginCreateTime = item.value[0];
              this.searchParam.endCreateTime = item.value[1];
            } else if (item.name === "appointmentTime") {
              this.searchParam.beginAppointmentTime = item.value[0];
              this.searchParam.endAppointmentTime = item.value[1];
            }
          } else {
            this.searchParam[item.name] = item.value;
          }
        }
      });
      this.getTransferNcList();
    },
    // 重置
    reset() {
      this.searchParamList.map((item) => {
        if (item.value || item.value == 0) {
          if(item.name === "createTime" ||
          item.name === "appointmentTime") {
            item.value = []
          } else {
            item.value = ""; 
          }
        }
      });
      this.pageParam = {
        pageNum: 1,
        pageSize: 20,
        sortItems: [
          {
            asc: false,
            column: "",
          },
        ],
      };
      this.search();
    },
    // 改变分页条数
    sizeChange(val) {
      this.pageParam.pageSize = val;
      this.getTransferNcList();
    },

    // 改变分页
    currentChange(val) {
      this.pageParam.pageNum = val;
      this.getTransferNcList();
    },
    // 排序
    sortChange(val) {
      this.pageParam.sortItems[0].asc = !(val.order == "descending")
      if(val.prop === "updateUserName") {
        this.pageParam.sortItems[0].column = "updateUserId";  
      } else {
        this.pageParam.sortItems[0].column = val.prop;
      }
      this.getTransferNcList();
    },
    // 打开编辑框
    async edit(row) {
      this.currCol = row;
      const { code, result } = await getCustomerPhone(row.customerId);
      if (code === 0) {
        this.phoneList = result;
        this.form.phone = result[0];
        this.form.customerId = row.customerId;
        this.form.id = row.id;
      }
      this.eidtShow = true;
    },
    // 省份联动校区
    provinceChange(value) {
      if (value) {
        this.province.map(item => {
          if(item.schoolId === value) {
            this.form.provinceName = item.name
          }
        })
        this.form.teacherCode = "";
        this.form.teacherName = "";
        this.form.schoolNcId = "";
        this.form.schoolName = "";
        getProvinceList({
          schoolId: value,
          trackType: this.currCol.trackType,
        }).then((data) => (this.schoolList = data.result));
      }
    },
    // 校区联动老师
    changeSchool(value) {
      if (value) {
        this.schoolList.map(item => {
          if(item.schoolId === value) {
            this.form.schoolName = item.name
          }
        })
        this.form.teacherCode = "";
        this.form.teacherName = ""
        getNcTeacher({ schoolNcId: value }).then(
          (data) => (this.teacherList = data.result)
        );
      }
    },
    changeTeacher(value) {
      if(value) {
        this.teacherList.map(item => {
          if(item.code == value) {
            this.form.teacherName = item.name
          }
        })
      }
    },
    // 保存编辑
    async editSave() {
      const { code, msg } = await getTransferNcUpdate(this.form);
      if (code === 0) {
        this.$message.success(msg);
        this.getTransferNcList();
      } else {
        this.$message.error(msg);
      }
      this.eidtShow = false;
    },

    // 取消编辑
    cancel() {
      this.form = {
        provinceCode: "",
        schoolNcId: "",
        teacherCode: "",
      }
      this.eidtShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.transferNc {
  // padding-bottom: 40px;
}
.el-col-24 {
  margin-top: 18px;
}
</style>
